import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  addInstagram,
  updateInstagram,
} from "../../../store/instagram/actions";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  email: yup.string().max(100).required(),
  password: yup.string().max(100).required(),

  //   note: yup.string(),
  //   image: yup.mixed().required(),
  // shownote: yup.bol().required(),
});

export default function AddNew({
  modal_center,
  tog_center,
  setmodal_center,
  data,
}) {
  const dispatch = useDispatch();

  const [files, setFiles] = useState(null);
  const [base64Image, setBase64Image] = useState("");

  const date = new Date(data.birthday);

  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: data.email,
      password: data.password,

      note: data.note,
      shownote: data.shownote,
      state: data.state,
    },
  });

  const onSubmit = (formData) => {
    if (Object.keys(data).length !== 0) {
      // let data = data.append("image", files);
      dispatch(
        updateInstagram({ ...formData, id: data.id, image: base64Image })
      );
    } else {
      // let data = data.append("image", files);
      dispatch(addInstagram({ ...formData, image: base64Image }));
    }
    if (!Object.keys(errors).length) {
      //   reset();
      setmodal_center(false);
    }
  };

  const imageUploadChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64Image(reader.result);
      };

      reader.readAsDataURL(file);
      setFiles(file);
    }

    //   const src = URL.createObjectURL(e.target.files[0]);
  };

  return (
    <Modal
      isOpen={modal_center}
      toggle={() => {
        tog_center();
      }}
      centered
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i class="ri-instagram-fill" style={{ paddingRight: 5 }}></i>{" "}
          {Object.keys(data).length !== 0 ? "Update" : "Add"} Instagram ID
        </h5>
        <button
          type="button"
          onClick={() => {
            setmodal_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-2">
            <Col md="12">
              <label for="file-input" className="filelabel">
                <input
                  id="file-input"
                  type="file"
                  className="fileinput"
                  onChange={imageUploadChange}
                />
                <img
                  className="logoimg"
                  id="img-preview"
                  onload="showToast()"
                  src={
                    files
                      ? URL.createObjectURL(files)
                      : data.image
                      ? `https://api.idsmega.com/public/storage/${data.image}`
                      : `https://api.idsmega.com/public/storage/logo.png`
                  }
                />
                <p>Click the Image to Upload</p>
              </label>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md="6">
              <div className="field">
                <input
                  {...register("email")}
                  className={`form-control ${
                    errors.email ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="email"
                />
                <span class="label">Enter email</span>
              </div>
            </Col>
            <Col md="6">
              <div className="field">
                <input
                  {...register("password")}
                  className={`form-control ${
                    errors.password ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                />
                <span class="label">Password</span>
              </div>
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col md="12">
              <div className="field  mb-3">
                <textarea
                  className={`form-control ${
                    errors.note ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="date"
                  {...register("note")}
                ></textarea>
                <span class="label">Note</span>
              </div>
            </Col>
            <Col md="6">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  {...register("shownote")}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  Show Note
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  {...register("state")}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  Sold
                </label>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => {
                setmodal_center(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              //   onClick={() => {
              //     setmodal_center(false);
              //   }}
              className="btn btn-lg btn-primary"
            >
              {Object.keys(data).length !== 0 ? "Update" : "Add New"}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
