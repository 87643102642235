import React from "react";

import { Col, Modal, Row, Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { fixApple } from "../../../store/facebook/actions";

const schema = yup.object().shape({
  accounts: yup.string().max(300).required(),
});

export default function AppleFixAccounts({
  modal_fix_accounts,
  tog_fix_accounts,
  setmodal_fix_accounts,
}) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    dispatch(fixApple({ formData }));

    if (!Object.keys(errors).length) {
      //   reset();
      setmodal_fix_accounts(false);
    }
  };

  return (
    <Modal
      isOpen={modal_fix_accounts}
      toggle={() => {
        tog_fix_accounts();
      }}
      centered
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i class="ri-apple-fill" style={{ paddingRight: 5 }}></i> Fix Apple
          IDs
        </h5>
        <button
          type="button"
          onClick={() => {
            setmodal_fix_accounts(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-2">
            <Col md="12" className="mb-2">
              <div className="field">
                <input
                  {...register("accounts")}
                  className={`form-control ${
                    errors.number ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                />
                <span class="label">Accounts Ids (123, 124, 125)</span>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => {
                setmodal_fix_accounts(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-lg btn-primary">
              Fix Accounts
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
