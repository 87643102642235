import Exports from "../../Pages/Mega/Exports";
import {
  ADD_EVENT_FAIL,
  ADD_EVENT_SUCCESS,
  GET_EVENTS_FAIL,
} from "../calendar/actionTypes";
import {
  ADD_FACEBOOK_SUCCESS,
  DELETE_FACEBOOK_SUCCESS,
  GET_FACEBOOK_FAIL,
  GET_FACEBOOK_SUCCESS,
  UPDATE_FACEBOOK_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  facebook: [],
  defective: [],
  exports: [],
  error: {},
};

const FACEBOOK = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FACEBOOK_SUCCESS:
      return {
        ...state,
        facebook: action.payload.facebook,
        defective: action.payload.defective,
      };

    case GET_FACEBOOK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_FACEBOOK_SUCCESS:
      return {
        ...state,
        facebook: [...state.facebook, action.payload],
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FACEBOOK_SUCCESS:
      return {
        ...state,
        facebook: state.facebook.map((id) =>
          id.id === action.payload.id ? { id, ...action.payload } : id
        ),
        isEventUpdated: true,
      };

    // case UPDATE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     isEventUpdated: false,
    //   };

    case DELETE_FACEBOOK_SUCCESS:
      return {
        ...state,
        facebook: state.facebook.filter((id) => id.id !== action.payload),
      };

    // case DELETE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default FACEBOOK;
