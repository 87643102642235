import React, { useState } from "react";

import { Col, Modal, Row, Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { exportInstagramQr } from "../../../store/instagram/actions";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  number: yup.number().max(100).required(),

  note: yup.string(),
  //   image: yup.mixed().required(),
  // shownote: yup.bol().required(),
});

export default function InstagramExportQr({
  modal_export_qr,
  tog_export_qr,
  setmodal_export_qr,
}) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [base64Image, setBase64Image] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const imageUploadChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64Image(reader.result);
      };

      reader.readAsDataURL(file);
      setFiles(file);
    }

    //   const src = URL.createObjectURL(e.target.files[0]);
  };

  const onSubmit = (formData) => {
    dispatch(exportInstagramQr({ ...formData, image: base64Image }));

    if (!Object.keys(errors).length) {
      //   reset();
      setmodal_export_qr(false);
    }
  };

  return (
    <Modal
      isOpen={modal_export_qr}
      toggle={() => {
        tog_export_qr();
      }}
      centered
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i class="ri-instagram-fill" style={{ paddingRight: 5 }}></i> Export
          Instagram IDs as QR
        </h5>
        <button
          type="button"
          onClick={() => {
            setmodal_export_qr(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-2">
            <Col md="12">
              <label for="file-input" className="filelabel">
                <input
                  id="file-input"
                  type="file"
                  className="fileinput"
                  onChange={imageUploadChange}
                />
                <img
                  className="logoimg"
                  id="img-preview"
                  onload="showToast()"
                  src={
                    files
                      ? URL.createObjectURL(files)
                      : `https://api.idsmega.com/public/storage/logo.png`
                  }
                />
                <p>Click the Image to Upload</p>
              </label>
            </Col>
            <Col md="12" className="mb-2">
              <div className="field">
                <input
                  {...register("number")}
                  className={`form-control ${
                    errors.number ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="number"
                />
                <span class="label">Enter Number</span>
              </div>
            </Col>
            <Col md="12">
              <div className="field  mb-3">
                <textarea
                  className={`form-control ${
                    errors.note ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="date"
                  {...register("note")}
                ></textarea>
                <span class="label">Note</span>
              </div>
            </Col>
            <Col md="12" className="mb-2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  {...register("shownote")}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  Show Note
                </label>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => {
                setmodal_export_qr(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              //   onClick={() => {
              //     setmodal_center(false);
              //   }}
              className="btn btn-lg btn-primary"
            >
              Export
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
